import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  let history = useNavigate();
  return (
    <div className="mt-[50px]">
      <div className="footerdivbg1">
        <div className="footerbg1fbx main-container w-[100%] text-[0px] relative mx-auto my-0 max-w-[1200px]">
          <div className="fffbox flex justify-between relative z-[7] mr-0 mb-0 ">
            <div className="footlog flex flex-col gap-[20px]">
              <div
                className="h-[50px] egoldbg bg-contain bg-no-repeat overflow-hidden z-[2]"
                onClick={() => {
                  history("/");
                }}
              />
              <span className="fmenu flex w-[226px] h-[34px] justify-start items-start font-['Poppins'] text-[13px] font-light leading-[16.9px] text-[#8a8a8a] top-[92px] text-left overflow-hidden z-[3]">
                The Egold Project is recognized <br />
                by its unique and best tokenomics.
              </span>
            </div>
            <div className="fmenu h-[307px] font-['Poppins'] text-[15px] font-light leading-[30px] text-left whitespace-nowrap z-[5]">
              <span className="font-['Poppins'] text-[20px] font-bold leading-[40px] text-[#000] relative text-left">
                EGOLD
                <br />
              </span>
              <span
                className="font-['Poppins'] text-[15px] font-light leading-[30px] text-[#000] relative text-left cursor-pointer"
                onClick={() => {
                  history("/tokenomics");
                }}
              >
                Better tokenomic
                <br />
              </span>
              <span className="font-['Poppins'] text-[15px] font-light leading-[30px] text-[#626262] relative text-left">
                Enviroment friendly
                <br />
                <a href="https://www.egoldstats.io/" target="_blank">
                  Mining statistics
                </a>
                <br />
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    history("/calculator");
                  }}
                >
                  Mining calculator
                </a>
                <br />
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    history("/terms");
                  }}
                >
                  Terms
                </a>
                <br />
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    history("/disclaimer");
                  }}
                >
                  Disclaimer
                </a>
                <br />
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    history("/privacy");
                  }}
                >
                  Privacy
                </a>
                <br />
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    history("/contact");
                  }}
                >
                  Contact
                </a>
                <br />
              </span>
            </div>
            <div className="fmenu h-[290px] font-['Poppins'] text-[15px] font-light leading-[30px] text-left whitespace-nowrap z-[6]">
              <span className="font-['Poppins'] text-[20px] font-bold leading-[40px] text-[#000] relative text-left">
                LEARN
                <br />
              </span>
              <span className="font-['Poppins'] text-[15px] font-light leading-[30px] text-[#626262] relative text-left">
                <a href="https://egold.gitbook.io/egold/" target="_blank">
                  Overview
                </a>
                <br />
                <a
                  href="https://egold.gitbook.io/egold/egold-tokenomics"
                  target="_blank"
                >
                  Egold Tokenomics
                </a>
                <br />
                <a
                  href="https://egold.gitbook.io/egold/how-to-use/how-to-register-on-egold"
                  target="_blank"
                >
                  How to use
                </a>
                <br />
                <a
                  href="https://egold.gitbook.io/egold/egold-mining-guide/introduction"
                  target="_blank"
                >
                  Egold Mining Guide
                </a>
                <br />
                <a
                  href="https://egold.gitbook.io/egold/list-of-miners"
                  target="_blank"
                >
                  List of Miners
                </a>
                <br />
                <a
                  href="https://egold.gitbook.io/egold/egold-staking"
                  target="_blank"
                >
                  Egold Staking
                </a>
                <br />
                <a
                  href="https://egold.gitbook.io/egold/project-roadmap"
                  target="_blank"
                >
                  Project Roadmap
                </a>
                <br />
              </span>
            </div>
            <div className="fmenu h-[157px] font-['Poppins'] text-[15px] font-light leading-[30px] text-left whitespace-nowrap z-[7]">
              <span className="font-['Poppins'] text-[20px] font-bold leading-[40px] text-[#000] relative text-left">
                CONTRACT
                <br />
              </span>
              <span className="font-['Poppins'] text-[15px] font-light leading-[30px] text-[#626262] relative text-left">
                <a
                  href={
                    "https://bscscan.com/token/" +
                    process.env.REACT_APP_EGOLDV1_ADDR
                  }
                  target="_blank"
                >
                  Egold V1
                </a>
                <br />
                <a
                  href={
                    "https://bscscan.com/token/" +
                    process.env.REACT_APP_EGOLDV2_ADDR
                  }
                  target="_blank"
                >
                  Egold V2
                </a>
                <br />
                <a
                  href={
                    "https://bscscan.com/token/" +
                    process.env.REACT_APP_CBK_ADDR
                  }
                  target="_blank"
                >
                  CBK - Cashback
                </a>
                <br />
                <a
                  href={
                    "https://bscscan.com/token/" +
                    process.env.REACT_APP_MINERNFT_ADDR
                  }
                  target="_blank"
                >
                  ANFT - Miner NFT
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="footerdivbg2">
        <div className="main-container w-[100%] text-[0px] relative mx-auto my-0 max-w-[1200px] py-[25px]">
          <div className="footerbg2fbx h-[45px] relative z-10 mr-0 mb-0 flex justify-between">
            <span className="flex h-[19px] justify-start items-start font-['Poppins'] text-[15px] font-light leading-[19px] text-[#8a8a8a] text-left whitespace-nowrap z-[4]">
              © 2024 The Egold Project. All rights reserved.
            </span>
            <div className="flex gap-[10px] h-full justify-between items-center z-10">
              <div className="w-[45.131px] h-[45px] xbg bg-[length:100%_100%] bg-no-repeat relative z-[9]" />

              <a
                href="https://t.me/egold_farm"
                class="telegram"
                target="_blank"
              >
              <div className="w-[45px] h-[45px] shrink-0 tgbg bg-[length:100%_100%] bg-no-repeat relative z-10" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
