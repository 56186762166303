import React, { useState, useEffect } from "react";

export default function EgoldSupply() {
  const [totalburnt, setTotalBurnt] = useState("...");
  const [dayburnt, setDayBurnt] = useState("...");
  const [currentmaxSupply, setcurrentmaxSupply] = useState("...");

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const urlMiningStats = "https://egold-miner.herokuapp.com/miningStats";
    const url24burn = "https://auth.egoldfarm.com/egoldburnedlast24hr";

    //getburnt
    fetch(urlMiningStats)
      .then(function (responseStats) {
        return responseStats.json();
      })
      .then(function (newStats) {
        setTotalBurnt(newStats.egoldSupply.burnt);
        setcurrentmaxSupply(21000000 - newStats.egoldSupply.burnt);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    //get24hrburnt
    fetch(url24burn)
      .then(function (responseStats) {
        return responseStats.json();
      })
      .then(function (newStats) {
        setDayBurnt(newStats.egoldburnlast24);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  };

  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return noround(num / 1000000, 2) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return noround(num,3); // if value < 1000, nothing to do
    }
  }

  return (
    <div className="egmainbox main-container w-[100%] text-[0px] relative mx-auto my-0 max-w-[900px]">
      <div className="egxflexboxes flex w-[100%] h-[172px] justify-between items-center relative z-[8] mt-0 mr-0 mb-0 ml-0">
        <div className="egindibox w-[383px] h-[172px] shrink-0 rounded-[20px] relative z-[1]">
          <div className="w-[383px] h-[172px] egsupplybg bg-cover bg-no-repeat absolute top-0 left-0 z-[2]">
            <span className="flex h-[59px] justify-start items-start font-['Poppins'] text-[60px] font-light leading-[59px] tracking--3px absolute top-[44px] left-[calc(50%-158.5px)] text-left whitespace-nowrap z-[5]">
              21M
            </span>
            <div className="w-[175.5px] h-[119.5px] dotlines bg-cover bg-no-repeat absolute top-[52px] left-[207px] z-[6]" />
            <span className="flex h-[20px] justify-start items-start font-['Poppins'] text-[20px] font-light leading-[19.8px] text-[#818181] tracking--1px absolute top-[108px] left-[calc(50%-131.5px)] text-left whitespace-nowrap z-[4]">
              Opening supply
            </span>
            <div className="w-[4.44%] h-[9.88%] egsopening  bg-[length:100%_100%] bg-no-repeat absolute top-[63.37%] left-[calc(50%-155px)] z-[3]" />
          </div>
        </div>
        <div className="egindibox w-[383px] h-[172px] shrink-0 rounded-[20px] relative z-[8]">
          <div className="w-[383px] h-[172px] egsupplybg bg-cover bg-no-repeat absolute top-0 left-0 z-[9]">
            <span className="flex h-[59px] justify-start items-start font-['Poppins'] text-[60px] font-light leading-[59px] tracking--3px absolute top-[44px] left-[calc(50%-158.5px)] text-left whitespace-nowrap z-[12]">
              {totalburnt == "..." ? "..." : numFormatter(totalburnt)}
            </span>
            <div className="w-[175.5px] h-[119.5px] dotlines bg-cover bg-no-repeat absolute top-[52px] left-[207px] z-[13]" />
            <span className="flex h-[20px] justify-start items-start font-['Poppins'] text-[20px] font-light leading-[19.8px] text-[#818181] tracking--1px absolute top-[108px] left-[calc(50%-131.5px)] text-left whitespace-nowrap z-[11]">
              Burn lifetime
            </span>
            <div className="w-[3.4%] h-[10.81%] egsburn bg-[length:100%_100%] bg-no-repeat absolute top-[62.98%] left-[calc(50%-155px)] z-10" />
          </div>
        </div>
      </div>
      <div className="egxflexboxes flex w-[100%] h-[172px] justify-between items-center relative z-[22] mt-[32px] mr-0 mb-0 ml-0">
        <div className="egindibox w-[383px] h-[172px] shrink-0 rounded-[20px] relative z-[15]">
          <div className="w-[383px] h-[172px] egsupplybg bg-cover bg-no-repeat absolute top-0 left-0 z-[16]">
            <span className="flex h-[59px] justify-start items-start font-['Poppins'] text-[60px] font-light leading-[59px] tracking--3px absolute top-[44px] left-[calc(50%-158.5px)] text-left whitespace-nowrap z-[19]">
              {dayburnt == "..." ? "..." : numFormatter(dayburnt)}
            </span>
            <div className="w-[175.5px] h-[119.5px] dotlines bg-cover bg-no-repeat absolute top-[52px] left-[207px] z-20" />
            <span className="flex h-[20px] justify-start items-start font-['Poppins'] text-[20px] font-light leading-[19.8px] text-[#818181] tracking--1px absolute top-[108px] left-[calc(50%-131.5px)] text-left whitespace-nowrap z-[18]">
              Burn past 24h
            </span>
            <div className="w-[18px] h-[18px] egslast24 bg-cover bg-no-repeat absolute top-[109px] left-[calc(50%-155px)] overflow-hidden z-[17]" />
          </div>
        </div>
        <div className="egindibox w-[383px] h-[172px] shrink-0 rounded-[20px] relative z-[22]">
          <div className="w-[383px] h-[172px] egsupplybg bg-cover bg-no-repeat absolute top-0 left-0 z-[23]">
            <span className="flex h-[59px] justify-start items-start font-['Poppins'] text-[60px] font-light leading-[59px] tracking--3px absolute top-[44px] left-[calc(50%-158.5px)] text-left whitespace-nowrap z-[26]">
              {currentmaxSupply == "..."
                ? "..."
                : numFormatter(currentmaxSupply)}
            </span>
            <div className="w-[175.5px] h-[119.5px] dotlines bg-cover bg-no-repeat absolute top-[52px] left-[207px] z-[27]" />
            <span className="flex h-[20px] justify-start items-start font-['Poppins'] text-[20px] font-light leading-[19.8px] text-[#818181] tracking--1px absolute top-[108px] left-[calc(50%-131.5px)] text-left whitespace-nowrap z-[24]">
              Max supply after burn
            </span>
            <div className="w-[20px] h-[20px] egsmax bg-cover bg-no-repeat absolute top-[108px] left-[calc(50%-155px)] overflow-hidden z-[25]" />
          </div>
        </div>
      </div>
    </div>
  );
}
