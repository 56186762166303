import React from "react";

export default function KeyFeatures() {
  return (
    <div className="kfmain main-container w-[100%] text-[0px] relative mx-auto my-0 max-w-[900px] kfdisplay">
      <span className="pb-[5px] flex justify-center items-start font-['Poppins'] text-[40px] font-bold leading-[60px] text-[#333] relative text-center whitespace-nowrap mt-0 mb-0 mx-auto">
        Key features
      </span>
      <span className="flex w-[219px] h-[70px] justify-start items-start font-['Poppins'] text-[13px] font-normal leading-[20px] text-[#919191] tracking--0.65px mx-auto">
          To learn more about the benefits of the $EGOLD ecosystem, click the
          link below.
        </span>

        <a href="https://egold.gitbook.io/egold/" target="_blank">
          <div className="w-[99px] h-[35px] stacbtn rounded-[17px] relative left-0 z-[3] mx-auto top-[10px]">
            <span className="flex w-[70px] h-[18px] justify-center items-start font-['Poppins'] text-[12px] font-bold leading-[18px] absolute top-[9px] left-[16px] text-center whitespace-nowrap z-[4]">
              Learn more
            </span>
          </div>
        </a>

      <div className="kfflexbx flex justify-between relative z-[11] mt-[80px] mr-0 mb-0 ml-0">
        <a href="https://egold.gitbook.io/egold/introduction/blockchain-agnostic" target="_blank">
        <div className="w-[173px]  font-['Poppins'] text-[30px] font-bold leading-[23px]  text-center z-[5]">
          <div className="w-[74.872px] h-[75px] shrink-0 m-auto mb-[20px] kfblock bg-[length:100%_100%] bg-no-repeat relative z-[6]" />
          <span className="font-['Poppins'] text-[20px] text-[#777777] font-bold mb-4 relative text-center">
            Block-chain
            <br />
            Agnostic
          </span>
          <br />
          <span className="font-['Poppins'] text-[13px] font-normal leading-[10px] top-[10px] text-[#919191] relative text-center">
            Egold project operates on series of smart contracts that can always
            be deployed to other chains this enables the project to shift
            blockchains.
          </span>
        </div>
        </a>
        <a href="https://egold.gitbook.io/egold/introduction/best-burn-mechanism" target="_blank">
        <div className="w-[173px] font-['Poppins'] text-[30px] font-bold leading-[23px] text-center z-[8]">
          <div className="w-[75px] h-[75px] shrink-0 m-auto mb-[20px] kfburn bg-cover bg-no-repeat relative overflow-hidden z-[9]" />
          <span className="font-['Poppins'] text-[20px] text-[#777777]  font-bold mb-4 relative text-center">
            Best-burn
            <br />
            Mechanism
          </span>
          <br />
          <span className="font-['Poppins'] text-[13px] font-normal leading-[10px] top-[10px] text-[#919191] relative text-center">
            We have implemented miner burn systems that ensure mines burn upto
            75% of all egold in circulation as they mine.
          </span>
        </div>
        </a>
        <a href="https://egold.gitbook.io/egold/introduction/extreme-deflation" target="_blank">
        <div className="w-[172px] font-['Poppins'] text-[30px] font-bold leading-[23px]   text-center z-[11]">
          <div className="w-[78px] h-[75px] shrink-0 m-auto mb-[20px] kfdeflation bg-cover bg-no-repeat relative overflow-hidden z-[12]" />
          <span className="font-['Poppins'] text-[20px] text-[#777777]  font-bold mb-4 relative text-center">
            Extreme
            <br />
            Deflation
          </span>
          <br />
          <span className="font-['Poppins'] text-[13px] font-normal leading-[10px] top-[10px] text-[#919191] relative text-center">
            Unlike Other coins which have 5 or 10 percent burn at egold we have
            designed the tokenomics that enable maximum burn that can be as high
            as 75%.
          </span>
        </div>
        </a>
        <a href="https://egold.gitbook.io/egold/introduction/eco-friendly-mining" target="_blank">
        <div className="w-[172.308px] font-['Poppins'] text-[30px] font-bold leading-[23px] text-center z-[2]">
          <div className="w-[89px] h-[75px] shrink-0 m-auto mb-[20px] kfmining bg-cover bg-no-repeat relative overflow-hidden z-[3]" />
          <span className="font-['Poppins'] text-[20px] text-[#777777]  font-bold mb-4 relative text-center">
            Eco-Friendly <br />
            Mining
          </span>
          <br />
          <span className="font-['Poppins'] text-[13px] font-normal leading-[10px] top-[10px] text-[#919191] relative text-center">
            Using the ANFT technology
            we have removed the need
            for electricity that traditional
            physical miners consume.
          </span>
        </div>
        </a>
      </div>
    </div>
  );
}
