import React from "react";

export default function Main() {
  return (
    <div className="kfmain main-container w-[810px] h-[340px] relative mx-auto my-0 kf2display py-5">
      <div className="w-[554px] h-[30px] relative z-[12] mt-0 mr-0 mb-0 ml-[239px]">
        <a href="https://egold.gitbook.io/egold/introduction/eco-friendly-mining" target="_blank">
        <div className="w-[191px] h-[25px] font-['Poppins'] text-[30px] font-bold leading-[25px] tracking--1.5px absolute top-0 left-[40px] text-left whitespace-nowrap z-[8]">
          <span className="font-['Poppins'] text-[20px] font-bold leading-[30px] text-[#000] tracking--1.5px relative text-left">
            Eco-Friendly Mining
          </span>
        </div>
        </a>
        <a href="https://egold.gitbook.io/egold/introduction/blockchain-agnostic" target="_blank">
        <span className="flex h-[25px] justify-start items-start font-['Poppins'] text-[20px] font-bold leading-[25px] text-[#000] tracking--1px absolute top-0 left-[335px] text-left whitespace-nowrap z-[12]">
          Blockchain Agnostic
        </span>
        </a>
        <div className="w-[4.33%] h-4/5 kfmining bg-[length:100%_100%] bg-no-repeat absolute top-[10%] left-[53.61%] z-[11]" />
        <div className="w-[28px] h-[28px] kfblock bg-cover bg-no-repeat absolute top-[5px] left-0 overflow-hidden z-[7]" />
      </div>
      <div className="w-[810px] h-[98px] relative z-[13] mt-[-8px] mr-0 mb-0 ml-0">
        <span className="flex w-[180px] h-[80px] justify-start items-start font-['Poppins'] text-[40px] font-bold leading-[39.6px] text-[#777777] tracking--2px absolute top-0 left-[calc(50%-405px)] text-left overflow-hidden z-[1]">
          Key <br />
          features
        </span>
        <div className="w-[219px] h-[75px] font-['Poppins'] text-[30px] font-bold leading-[20px] tracking--1.5px absolute top-[8px] left-[279px] text-left z-[9]">
          <span className="font-['Poppins'] text-[13px] font-normal leading-[10px] text-[#919191] tracking--1.5px relative text-left">
            Using the ANFT technology we have removed the need for electricity
            that traditional physical miners consume.
          </span>
        </div>
        <div className="w-[236px] h-[90px] font-['Poppins'] text-[30px] font-bold leading-[20px] tracking--1.5px absolute top-[8px] left-[574px] text-left z-[13]">
          <span className="font-['Poppins'] text-[13px] font-normal leading-[10px] text-[#919191] tracking--1.5px relative text-left">
            Egold project operates on series of smart contracts that can always
            be deployed to other chains this enables the project to shift
            blockchains.
          </span>
        </div>
      </div>
      <div className="w-[810px] h-[163px] relative z-[21] mt-[10px] mr-0 mb-0 ml-0">
        <span className="flex w-[219px] h-[70px] justify-start items-start font-['Poppins'] text-[13px] font-normal leading-[20px] text-[#919191] tracking--0.65px absolute top-0 left-px text-left z-[5]">
          To learn more about the benefits of the $EGOLD ecosystem, click the
          link below.
        </span>
        <a href="https://egold.gitbook.io/egold/introduction/best-burn-mechanism" target="_blank">
        <span className="flex h-[25px] justify-start items-start font-['Poppins'] text-[20px] font-bold leading-[25px] text-[#000] tracking--1px absolute top-[23px] left-[279px] text-left whitespace-nowrap z-[16]">
          Best-burn Mechanism
        </span>
        </a>
        <a href="https://egold.gitbook.io/egold/introduction/extreme-deflation" target="_blank">
        <span className="flex h-[25px] justify-start items-start font-['Poppins'] text-[20px] font-bold leading-[25px] text-[#000] tracking--1px absolute top-[23px] left-[574px] text-left whitespace-nowrap z-20">
          Extreme Deflation
        </span>
        </a>
        <div className="w-[28px] h-[28px] kfburn bg-cover bg-no-repeat absolute top-[26px] left-[533px] overflow-hidden z-[19]" />
        <div className="w-[3.21%] h-[15.95%] kfdeflation bg-[length:100%_100%] bg-no-repeat absolute top-[17.18%] left-[29.75%] overflow-hidden z-[15]" />
        <div className="w-[219px] h-[90px] font-['Poppins'] text-[30px] font-bold leading-[20px] tracking--1.5px absolute top-[53px] left-[279px] text-left z-[17]">
          <span className="font-['Poppins'] text-[13px] font-normal leading-[10px] text-[#919191] tracking--1.5px relative text-left">
            We have implemented miner burn systems that ensure mines burn upto
            75% of all egold in circulation as they mine.
          </span>
        </div>
        <div className="w-[236px] h-[110px] font-['Poppins'] text-[30px] font-bold leading-[20px] tracking--1.5px absolute top-[53px] left-[574px] text-left z-[21]">
          <span className="font-['Poppins'] text-[13px] font-normal leading-[10px] text-[#919191] tracking--1.5px relative text-left">
            Unlike Other coins which have 5 or 10 percent burn at egold we have
            designed the tokenomics that enable maximum burn that can be as high
            as 75%.
          </span>
        </div>
        <a href="https://egold.gitbook.io/egold/" target="_blank">
          <div className="w-[115px] h-[35px] stacbtn rounded-[17px] absolute top-[76px] left-0 z-[3]">
            <span className="flex w-[70px] h-[18px] justify-center items-start font-['Poppins'] text-[12px] font-bold leading-[18px] absolute top-[9px] left-[16px] text-center whitespace-nowrap z-[4]">
              Learn more
            </span>
          </div>
        </a>
      </div>
    </div>
  );
}
