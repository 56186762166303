import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import Loader from "../Loader";
import abiVal from "./data/lpFarm.json";

const TotaleSupply = () => {
  const Web3 = require("web3");
  var FARMING_ABI = require("./data/farmAbi.json");
  const PANCAKE_FACTORY_ABI = require("./data/pancakeFactoryAbi.json");
  const LIQUIDITY_ABI = require("./data/liquidityAbi.json");
  const PANCAKE_FACTORY_ADDRESS = process.env.REACT_APP_PANCAKE_FACTORY_ADDR;
  const FARMING_ADDRESS = process.env.REACT_APP_FARMING_ADDR;
  const EGOLD_ADDRESS = process.env.REACT_APP_EGOLDV1_ADDR;
  const EGOLDV2_ADDRESS = process.env.REACT_APP_EGOLDV2_ADDR;
  const BUSD_ADDRESS = process.env.REACT_APP_BUSD_ADDR;
  const USDT_ADDRESS = process.env.REACT_APP_USDT_ADDR;
  const BUSD_EGOLD_FARM_ADDRESS = process.env.REACT_APP_BUSD_EGOLD_FARM;
  const BUSDFARM_ABI = abiVal.farmabi;

  const [data, setData] = useState([]);
  const [mintedtotal, setMintedTotal] = useState("...");
  const [miningStats, setMiningStats] = useState({
    data: "...",
    loading: true,
  });
  const [liquidityEGOLDBlnce, setliquidityEGOLDBlnce] = useState("...");
  const [liquidityEGOLDv2Blnce, setliquidityEGOLDv2Blnce] = useState("...");
  const [staketotal, setStakeTotal] = useState("...");
  const [farmyield, setFarmYield] = useState("...");
  const [salerburn, setSalerburn] = useState("...");

  const web3 = new Web3(process.env.REACT_APP_RPC);
  const farmInstance = new web3.eth.Contract(FARMING_ABI, FARMING_ADDRESS);
  const pancakeFactorycontractInstance = new web3.eth.Contract(
    PANCAKE_FACTORY_ABI,
    PANCAKE_FACTORY_ADDRESS
  );

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const urlMiningStats = "https://egold-miner.herokuapp.com/miningStats";
    const totalstakeurl =
      "https://egold-staking-transactions.herokuapp.com/totalegoldstaked";
    const salerburn = "https://auth.egoldfarm.com/totalegburned";

    //fetch sales burn
    fetch(salerburn)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setSalerburn(newData.total_egold_burned);
      })
      .catch(function (error) {
        console.log(error);
      });

    await pancakeFactorycontractInstance.methods
      .getPair(BUSD_ADDRESS, EGOLD_ADDRESS)
      .call({}, function (e, res) {
        const LQInstance = new web3.eth.Contract(LIQUIDITY_ABI, res);
        console.log("RESPONSE LQ ", res);
        LQInstance.methods.getReserves().call({}, function (e, reserve) {
          console.log("reeserves");
          setliquidityEGOLDBlnce(
            parseFloat(web3.utils.fromWei(reserve[0])).toFixed(3)
          );
        });
      });

    await pancakeFactorycontractInstance.methods
      .getPair(USDT_ADDRESS, EGOLDV2_ADDRESS)
      .call({}, function (e, res) {
        const LQInstance = new web3.eth.Contract(LIQUIDITY_ABI, res);
        console.log("RESPONSE LQ ", res);
        LQInstance.methods.getReserves().call({}, function (e, reserve) {
          console.log("reeserves", web3.utils.fromWei(reserve[1]));
          setliquidityEGOLDv2Blnce(
            parseFloat(web3.utils.fromWei(reserve[1])).toFixed(3)
          );
        });
      });

    const depositLPTInstance = await new web3.eth.Contract(
      BUSDFARM_ABI,
      BUSD_EGOLD_FARM_ADDRESS
    );

    await depositLPTInstance.methods
      .paidOut()
      .call({}, async function (e, res) {
        setFarmYield(parseFloat(web3.utils.fromWei(res)));
      });

    //fetch total stake
    fetch(totalstakeurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setStakeTotal(newData.totalStaked);
      })
      .catch(function (error) {
        console.log(error);
      });

    //MiningStats
    fetch(urlMiningStats)
      .then(function (responseStats) {
        return responseStats.json();
      })
      .then(function (newStats) {
        setMiningStats({ data: newStats, loading: false });
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    await farmInstance.methods.paidOut().call({}, async function (e, res) {
      setMintedTotal(
        parseFloat(parseFloat(web3.utils.fromWei(res)).toFixed(2))
      );
    });
  };

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        fill="black"
        style={{
          fontSize: "20px",
        }}
      >
        {numFormatter(
          farmyield +
            15000 +
            (mintedtotal - miningStats.data.egoldSupply.burnt) -
            noround(salerburn, 2)
        )}{" "}
      </text>
    );
  };

  useEffect(() => {
    if (
      mintedtotal !== "..." &&
      miningStats.data !== "..." &&
      liquidityEGOLDBlnce !== "..." &&
      staketotal !== "..." &&
      farmyield !== "..."
    ) {
      setData([
        {
          id: "Locked(Liquidity)",
          label: "Locked in Liquidity",
          value: noround(
            parseFloat(liquidityEGOLDBlnce) + parseFloat(liquidityEGOLDv2Blnce),
            2
          ),
          color: "#d94126",
        },
        {
          id: "Locked(Staking)",
          label: "Locked for Staking",
          value: noround(staketotal, 2),
          color: "#e3e3e3",
        },
        {
          id: "Held By User",
          label: "Held By User",
          value: noround(
            farmyield +
              15000 +
              (mintedtotal - miningStats.data.egoldSupply.burnt) -
              liquidityEGOLDBlnce -
              liquidityEGOLDv2Blnce -
              staketotal,
            2
          ),
          color: "#56d926",
        },
      ]);
    }
  }, [mintedtotal, farmyield, miningStats, staketotal, liquidityEGOLDBlnce]);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + " K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return noround(num / 1000000, 2) + " M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      {mintedtotal == "..." ||
      farmyield == "..." ||
      miningStats.data == "..." ||
      staketotal == "..." ||
      liquidityEGOLDBlnce == "..." ||
      salerburn.loading ? (
        <div className="cocont container mx-auto mt-4 mb-16 p-5 sm:mt-2 lg:mt-10 px-10 lg:p-[30px] bg-[#fff] rounded-lg">
          {" "}
          <Loader />
        </div>
      ) : (
        <div className="chartbx">
          <div className="chbxhdiv">
            <ResponsivePie
              data={data}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              valueFormat=" >-,"
              startAngle={0}
              sortByValue={false}
              arcLinkLabelsStraightLength={10}
              activeOuterRadiusOffset={8}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLabelsRadiusOffset={0.61}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              layers={[
                "arcs",
                "arcLabels",
                "arcLinkLabels",
                "legends",
                CenteredMetric,
              ]}
              colors={{ scheme: "green_blue" }}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: window.innerWidth < 500 ? 60 : 56,
                  itemsSpacing: 0,
                  itemWidth: window.innerWidth < 500 ? 110 : 140,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection:
                    window.innerWidth < 500 ? "top-to-bottom" : "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
          <div className="flex flex-col chtable">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-left text-sm font-light">
                    <tbody>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          Active EGOLD Supply
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(
                              farmyield +
                                15000 +
                                (mintedtotal -
                                  miningStats.data.egoldSupply.burnt) -
                                noround(salerburn, 2),
                              2
                            )
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          Locked in Liquidity
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(
                              parseFloat(liquidityEGOLDBlnce) +
                                parseFloat(liquidityEGOLDv2Blnce),
                              2
                            )
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          Locked for Staking
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(noround(staketotal, 2)).toLocaleString()}
                        </td>
                      </tr>
                      <tr>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          Held By User
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(
                              farmyield +
                                15000 +
                                (mintedtotal -
                                  miningStats.data.egoldSupply.burnt) -
                                noround(salerburn, 2) -
                                parseFloat(liquidityEGOLDBlnce) -
                                parseFloat(liquidityEGOLDv2Blnce) -
                                staketotal,
                              2
                            )
                          ).toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TotaleSupply;
