import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import Loader from "../Loader";

const TotaleSupply = () => {
  const [data, setData] = useState([]);
  const [gcount1, setGcount1] = useState("...");
  const [gcount2, setGcount2] = useState("...");
  const [gcount3, setGcount3] = useState("...");
  const [gcount4, setGcount4] = useState("...");
  const [gcount5, setGcount5] = useState("...");
  const [gcount6, setGcount6] = useState("...");
  const [gcount7, setGcount7] = useState("...");
  const [gcount8, setGcount8] = useState("...");
  const [gcount9, setGcount9] = useState("...");
  const [gmcount, setGmcount] = useState("...");

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const g1count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/1";
    const g2count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/2";
    const g3count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/3";
    const g4count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/4";

    const g5count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/5";
    const g6count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/6";
    const g7count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/7";
    const g8count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/8";
    const g9count =
      "https://egold-treasury-api.herokuapp.com/v1/summary/countMiners/9";
    const gmcount = "https://auth.egoldfarm.com/getgiftminercount";

    //FETCH G1
    fetch(g1count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("G DATA", newData.count);
        setGcount1(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G2
    fetch(g2count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount2(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G3
    fetch(g3count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount3(newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G4
    fetch(g4count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount4(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G5
    fetch(g5count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount5(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G6
    fetch(g6count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount6(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G7
    fetch(g7count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount7(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G8
    fetch(g8count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount8(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH G9
    fetch(g9count)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGcount9(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    //FETCH gift miner count
    fetch(gmcount)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setGmcount(newData.count);
        console.log("GRR DATA", newData.count);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  };

  useEffect(() => {
    if (
      gcount1 !== "..." &&
      gcount2 !== "..." &&
      gcount3 !== "..." &&
      gcount4 !== "..." &&
      gcount5 !== "..." &&
      gcount6 !== "..." &&
      gcount7 !== "..." &&
      gcount8 !== "..." &&
      gcount9 !== "..." &&
      gmcount !== "..."
    ) {
      setData([
        {
          id: "G1",
          label: "G1",
          value: gcount1 * 20,
          color: "#d94126",
        },
        {
          id: "G2",
          label: "G2",
          value: gcount2 * 100,
          color: "#d94126",
        },
        {
          id: "G3",
          label: "G3",
          value: gcount3 * 500,
          color: "#d94126",
        },
        {
          id: "G4",
          label: "G4",
          value: gcount4 * 2500,
          color: "#d94126",
        },
        {
          id: "S1",
          label: "S1",
          value: gcount5 * 50,
          color: "#d94126",
        },
        {
          id: "S2",
          label: "S2",
          value: gcount6 * 250,
          color: "#d94126",
        },
        {
          id: "S3",
          label: "S3",
          value: gcount7 * 1250,
          color: "#d94126",
        },
        {
          id: "S4",
          label: "S4",
          value: gcount8 * 6250,
          color: "#d94126",
        },
        {
          id: "S5",
          label: "S5",
          value: gcount9 * 12500,
          color: "#d94126",
        },
        {
          id: "Other",
          label: "Other",
          value: gmcount * 6,
          color: "#d94126",
        },
      ]);
    }
  }, [
    gcount1,
    gcount2,
    gcount3,
    gcount4,
    gcount5,
    gcount6,
    gcount7,
    gcount8,
    gcount9,
    gmcount,
  ]);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return noround(num / 1000000, 2) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      {gcount1 == "..." ||
      gcount2 == "..." ||
      gcount3 == "..." ||
      gcount4 == "..." ||
      gcount5 == "..." ||
      gcount6 == "..." ||
      gcount7 == "..." ||
      gcount8 == "..." ||
      gcount9 == "..." ||
      gmcount == "..." ? (
        <div className="cocont container mx-auto mt-4 mb-16 p-5 sm:mt-2 lg:mt-10 px-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg">
          {" "}
          <Loader />
        </div>
      ) : (
        <div className="chartbx">
          <div className="chbxhdiv">
            <ResponsivePie
              data={data}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              valueFormat=" >-,"
              startAngle={0}
              sortByValue={false}
              arcLinkLabelsStraightLength={15}
              activeOuterRadiusOffset={8}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={20}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 2]],
              }}
              colors={{ scheme: "yellow_orange_red" }}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: window.innerWidth < 500 ? 60 : 56,
                  itemsSpacing: 0,
                  itemWidth: window.innerWidth < 500 ? 50 : 60,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection:
                    window.innerWidth < 500 ? "top-to-bottom" : "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
          <div className="flex flex-col chtable">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-left text-sm font-light">
                    <tbody>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          G1 Metahash
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(gcount1 * 20, 2)
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          G2 Metahash
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(gcount2 * 100, 2)
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          G3 Metahash
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(gcount3 * 500, 2)
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          G4 Metahash
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(gcount4 * 2500, 2)
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          S1 Metahash
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(gcount5 * 50, 2)
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          S2 Metahash
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(gcount6 * 250, 2)
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          S3 Metahash
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(gcount7 * 1250, 2)
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          S4 Metahash
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(gcount8 * 6250, 2)
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr className="border-b border-neutral-300">
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          S5 Metahash
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(gcount9 * 12500, 2)
                          ).toLocaleString()}
                        </td>
                      </tr>
                      <tr>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          Other Metahash
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(noround(gmcount * 6, 2)).toLocaleString()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TotaleSupply;
