import React from "react";

export default function Disclaimer() {
    return (
        <div className="container mx-auto  mb-16 pb-5 mt-[100px] md:mt-[50px] lg:mt-10 px-10 lg:pb-[30px] bg-[#dfe7ea] rounded-lg">
            <h1 className="uppercase text-center font-bold pt-8 pb-10 text-base text-[#585858] ">
                {window.location.pathname.replace("/", "")}
            </h1>
            <p className="text-[15px] text-[#585858] leading-8">
                EGOLD is a decentralized NFT project that operates on the blockchain. The EGOLD Project ANFT Miners mine EGOLD Tokens. The value of EGOLD tokens are not fixed or managed by the team. There are risks associated with participating in The EGOLD Project. Before buying an EGOLD Miner, you should carefully consider your objectives, level of experience, and risk appetite.

                The information provided on the EGOLD website, whitepaper, ANFT miners and any other materials related to the project should not be construed as financial or investment advice. EGOLD is not responsible for any financial decisions you make.
                EGOLD does not guarantee the accuracy, completeness, or reliability of any information provided about the project. Any information provided by EGOLD is subject to change without notice.

                Buying an EGOLD Miner NFT carries risks, including the potential loss in your share of participation. EGOLD is not liable for any direct, indirect, incidental, or consequential damages resulting from your participation in the project.

                EGOLD is not responsible for any actions taken by third-party service providers, including exchanges or wallets that support EGOLD. You should carefully consider the risks associated with using third-party services before using them.

                EGOLD reserves the right to modify or update this disclaimer at any time without notice. By participating in EGOLD, you acknowledge that you have read and understand this disclaimer and agree to its terms and conditions.

            </p>
            <br />
        </div>
    );
}
