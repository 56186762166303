import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import Loader from "../Loader";

const Difficulty = () => {
  const [data, setData] = useState([]);
  const [egval, setEgval] = useState("10");
  const [dataload, setDataLoading] = useState(true);
  const [miningStats, setMiningStats] = useState({
    data: "...",
    loading: true,
  });

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const urlMiningStats = "https://egold-miner.herokuapp.com/miningStats";

    //MiningStats
    fetch(urlMiningStats)
      .then(function (responseStats) {
        return responseStats.json();
      })
      .then(function (newStats) {
        setMiningStats({ data: newStats, loading: false });
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  };

  useEffect(() => {
    if (miningStats.loading == false) {
      let totdt = [];
      let egoldval = egval ? parseInt(egval) : 1;

      let dt20 = [{ x: 0, y: 0 }];
      let totalmp = miningStats.data.miningRate.miningPower;
      for (var i = 1000000; i < totalmp; i += 1000000) {
        var days = parseInt((i * egoldval) / (6849.315069 * 20));
        dt20.push({ x: numFormatter(i), y: days });
      }
      var fday = parseInt((totalmp * egoldval) / (6849.315069 * 20));
      dt20.push({ x: totalmp, y: fday });
      totdt.push({ id: "20MH", color: "hsl(16, 70%, 50%)", data: dt20 });

      let dt50 = [{ x: 0, y: 0 }];
      for (var i = 1000000; i < totalmp; i += 1000000) {
        var days = parseInt((i * egoldval) / (6849.315069 * 50));
        dt50.push({ x: numFormatter(i), y: days });
      }
      var fday = parseInt((totalmp * egoldval) / (6849.315069 * 50));
      dt50.push({ x: totalmp, y: fday });
      totdt.push({ id: "50MH", color: "hsl(16, 70%, 50%)", data: dt50 });

      let dt100 = [{ x: 0, y: 0 }];
      for (var i = 1000000; i < totalmp; i += 1000000) {
        var days = parseInt((i * egoldval) / (6849.315069 * 100));
        dt100.push({ x: numFormatter(i), y: days });
      }
      var fday = parseInt((totalmp * egoldval) / (6849.315069 * 100));
      dt100.push({ x: totalmp, y: fday });
      totdt.push({ id: "100MH", color: "hsl(126, 70%, 50%)", data: dt100 });

      let dt250 = [{ x: 0, y: 0 }];
      for (var i = 1000000; i < totalmp; i += 1000000) {
        var days = parseInt((i * egoldval) / (6849.315069 * 250));
        dt250.push({ x: numFormatter(i), y: days });
      }
      var fday = parseInt((totalmp * egoldval) / (6849.315069 * 250));
      dt250.push({ x: totalmp, y: fday });
      totdt.push({ id: "250MH", color: "hsl(126, 70%, 50%)", data: dt250 });

      let dt500 = [{ x: 0, y: 0 }];
      for (var i = 1000000; i < totalmp; i += 1000000) {
        var days = parseInt((i * egoldval) / (6849.315069 * 500));
        dt500.push({ x: numFormatter(i), y: days });
      }
      var fday = parseInt((totalmp * egoldval) / (6849.315069 * 500));
      dt500.push({ x: totalmp, y: fday });
      totdt.push({ id: "500MH", color: "hsl(289, 70%, 50%)", data: dt500 });

      let dt1250 = [{ x: 0, y: 0 }];
      for (var i = 1000000; i < totalmp; i += 1000000) {
        var days = parseInt((i * egoldval) / (6849.315069 * 1250));
        dt250.push({ x: numFormatter(i), y: days });
      }
      var fday = parseInt((totalmp * egoldval) / (6849.315069 * 1250));
      dt1250.push({ x: totalmp, y: fday });
      totdt.push({ id: "1250MH", color: "hsl(126, 70%, 50%)", data: dt250 });

      let dt2500 = [{ x: 0, y: 0 }];
      for (var i = 1000000; i < totalmp; i += 1000000) {
        var days = parseInt((i * egoldval) / (6849.315069 * 2500));
        dt2500.push({ x: numFormatter(i), y: days });
      }
      var fday = parseInt((totalmp * egoldval) / (6849.315069 * 2500));
      dt2500.push({ x: totalmp, y: fday });
      totdt.push({ id: "2500MH", color: "hsl(289, 70%, 50%)", data: dt2500 });

      let dt6250 = [{ x: 0, y: 0 }];
      for (var i = 1000000; i < totalmp; i += 1000000) {
        var days = parseInt((i * egoldval) / (6849.315069 * 6250));
        dt250.push({ x: numFormatter(i), y: days });
      }
      var fday = parseInt((totalmp * egoldval) / (6849.315069 * 6250));
      dt6250.push({ x: totalmp, y: fday });
      totdt.push({ id: "6250MH", color: "hsl(126, 70%, 50%)", data: dt6250 });

      let dt12500 = [{ x: 0, y: 0 }];
      for (var i = 1000000; i < totalmp; i += 1000000) {
        var days = parseInt((i * egoldval) / (6849.315069 * 12500));
        dt12500.push({ x: numFormatter(i), y: days });
      }
      var fday = parseInt((totalmp * egoldval) / (6849.315069 * 12500));
      dt12500.push({ x: totalmp, y: fday });
      totdt.push({ id: "12500MH", color: "hsl(289, 70%, 50%)", data: dt12500 });

      setData(totdt);
      setDataLoading(false);
    }
  }, [miningStats, egval]);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return noround(num / 1000000, 1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      {miningStats.data == "..." || dataload == true ? (
        <div className="cocont container mx-auto mt-4 mb-16 p-5 sm:mt-2 lg:mt-10 px-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg">
          {" "}
          <Loader />
        </div>
      ) : (
        <>
          <p
            className="text-center py-[13px] text-[#8c8c8c]"
            style={{
              fontSize: "10px",
              width: "70%",
              minWidth: "300px",
              margin: "auto",
            }}
          >
            {" "}
            Compares the difficulty of different miners to mine{" "}
            <input
              type="text"
              className="border-b border-gray-500 bg-transparent outline-none text-[15px] text-center"
              style={{ width: `${egval.length + 1}ch` }}
              value={egval}
              onChange={(e) => setEgval(e.target.value)}
            />{" "}
            EGOLD
          </p>
          <div className="chartbx">
            <div className="chbxhdiv">
              <ResponsiveLine
                data={data}
                margin={
                  window.innerWidth < 500
                    ? { top: 15, right: 22, bottom: 70, left: 30 }
                    : { top: 15, right: 110, bottom: 50, left: 80 }
                }
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Total MetaHash",
                  legendOffset: window.innerWidth < 500 ? 38 : 44,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Days to Mine " + egval + " EGOLD",
                  legendOffset: -45,
                  legendPosition: "middle",
                }}
                colors={{ scheme: "category10" }}
                pointSize={5}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                  {
                    anchor: window.innerWidth < 500 ? "bottom" : "right",
                    direction: window.innerWidth < 500 ? "row" : "column",
                    justify: false,
                    translateX: window.innerWidth < 500 ? 5 : 96,
                    translateY: window.innerWidth < 500 ? 74 : 76,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
            <br className="mobnone" />
            <div className="flex flex-col chtable">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                  <div className="overflow-hidden">
                    <table className="min-w-full text-left text-sm font-light">
                      <tbody>
                        <tr className="border-b border-neutral-300">
                          <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                            Current S1 Difficulty
                          </td>
                          <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                            {parseFloat(
                              noround(
                                (miningStats.data.miningRate.miningPower *
                                  (egval ? parseInt(egval) : 1)) /
                                  (6849.315069 * 50),
                                2
                              )
                            ).toLocaleString()}{" "}
                            Days
                          </td>
                        </tr>
                        <tr className="border-b border-neutral-300">
                          <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                            Current S2 Difficulty
                          </td>
                          <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                            {parseFloat(
                              noround(
                                (miningStats.data.miningRate.miningPower *
                                  (egval ? parseInt(egval) : 1)) /
                                  (6849.315069 * 250),
                                2
                              )
                            ).toLocaleString()}{" "}
                            Days
                          </td>
                        </tr>
                        <tr className="border-b border-neutral-300">
                          <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                            Current S3 Difficulty
                          </td>
                          <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                            {parseFloat(
                              noround(
                                (miningStats.data.miningRate.miningPower *
                                  (egval ? parseInt(egval) : 1)) /
                                  (6849.315069 * 1250),
                                2
                              )
                            ).toLocaleString()}{" "}
                            Days
                          </td>
                        </tr>
                        <tr className="border-b border-neutral-300">
                          <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                            Current S4 Difficulty
                          </td>
                          <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                            {parseFloat(
                              noround(
                                (miningStats.data.miningRate.miningPower *
                                  (egval ? parseInt(egval) : 1)) /
                                  (6849.315069 * 6250),
                                2
                              )
                            ).toLocaleString()}{" "}
                            Days
                          </td>
                        </tr>
                        <tr className="border-b border-neutral-300">
                          <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                            Current S5 Difficulty
                          </td>
                          <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                            {parseFloat(
                              noround(
                                (miningStats.data.miningRate.miningPower *
                                  (egval ? parseInt(egval) : 1)) /
                                  (6849.315069 * 12500),
                                2
                              )
                            ).toLocaleString()}{" "}
                            Days
                          </td>
                        </tr>
                        <tr className="border-b border-neutral-300">
                          <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                            Current G1 Difficulty
                          </td>
                          <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                            {parseFloat(
                              noround(
                                (miningStats.data.miningRate.miningPower *
                                  (egval ? parseInt(egval) : 1)) /
                                  (6849.315069 * 20),
                                2
                              )
                            ).toLocaleString()}{" "}
                            Days
                          </td>
                        </tr>
                        <tr className="border-b border-neutral-300">
                          <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                            Current G2 Difficulty
                          </td>
                          <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                            {parseFloat(
                              noround(
                                (miningStats.data.miningRate.miningPower *
                                  (egval ? parseInt(egval) : 1)) /
                                  (6849.315069 * 100),
                                2
                              )
                            ).toLocaleString()}{" "}
                            Days
                          </td>
                        </tr>
                        <tr className="border-b border-neutral-300">
                          <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                            Current G3 Difficulty
                          </td>
                          <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                            {parseFloat(
                              noround(
                                (miningStats.data.miningRate.miningPower *
                                  (egval ? parseInt(egval) : 1)) /
                                  (6849.315069 * 500),
                                2
                              )
                            ).toLocaleString()}{" "}
                            Days
                          </td>
                        </tr>
                        <tr>
                          <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                            Current G4 Difficulty
                          </td>
                          <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                            {parseFloat(
                              noround(
                                (miningStats.data.miningRate.miningPower *
                                  (egval ? parseInt(egval) : 1)) /
                                  (6849.315069 * 2500),
                                2
                              )
                            ).toLocaleString()}{" "}
                            Days
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Difficulty;
