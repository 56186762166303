import React from "react";

export default function Terms() {
    return (
        <div className="container mx-auto  mb-16 pb-5 mt-[100px] md:mt-[50px] lg:mt-10 px-10 lg:pb-[30px] bg-[#dfe7ea] rounded-lg">
            <h1 className="uppercase text-center font-bold pt-8 pb-10 text-base text-[#585858] ">
                {window.location.pathname.replace("/", "")}
            </h1>
            <p className="text-[15px] text-[#585858] leading-8">
                Welcome to EGOLD, a Web3 NFT project that operates on the blockchain. By using our services, you agree to comply with these Terms of Use. If you do not agree with these terms, please do not use our services.

                Use of Services: You may use our Web3 project to interact with our dApp, browse our website, or participate in our community. You agree to use our services for lawful purposes only and not to engage in any activities that may violate any laws or regulations.

                Intellectual Property: All content, trademarks, logos, and other intellectual property displayed on our website or dApp are the property of EGOLD or our licensors. You may not use any of our intellectual property without our prior written consent.

                No Investment Advice: EGOLD is not a financial or investment advisor and does not provide any investment advice. The information provided on our website or dApp is for informational purposes only and should not be construed as investment advice. You should always consult with a financial advisor before making any investment decisions.

                No Guarantees: EGOLD makes no guarantees or warranties about the accuracy, reliability, completeness, or timeliness of any information provided on our website or dApp. We also do not guarantee the performance or success of our project.

                Risk Warning: Using our Web3 project involves risks, including the potential loss of your investment. You should carefully consider your investment objectives, level of experience, and risk appetite before using our services.

                Privacy Policy: By using our services, you agree to our privacy policy, which outlines how we collect, use, and safeguard your personal information.

                Indemnification: You agree to indemnify and hold EGOLD and our officers, directors, employees, and affiliates harmless from any losses, damages, or expenses, including attorneys’ fees, arising from your use of our services or your violation of these Terms of Use.

                Governing Law: These Terms of Use shall be governed by and construed in accordance with the laws of U.A.E., without giving effect to any principles of conflicts of law.

                Changes to Terms of Use: We may update these Terms of Use from time to time. We will notify you of any significant changes by posting the new Terms of Use on our website.

                Contact Us: If you have any questions about these Terms of Use or our services, please contact us at contact@egold.farm

            </p>
            <br />
        </div>
    );
}
