import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Loader from "../../Loader";



export default function PriceChange() {
  const [tradestate, setTradestate] = useState({ data: null });
  const [tdata, setTData] = useState({ data: null, loading: true });
  const [marketstate, setMarketstate] = useState({ data: null, loading: true });
  const [highestPrice, setHighestPrice] = useState("...");
  const [lowestPrice, setLowestPrice] = useState("...");

  useEffect(() => {
    getSummary();
  }, []);

  useEffect(() => {
    if (tradestate.data != null) {
      const tradeArray = tradestate.data.map(([name, price]) => ({ name, price }));
      
    console.log("tradearraa",tradeArray)
      setTData({ data: tradeArray, loading: false });
      const { highestPrice, lowestPrice } = tradeArray.reduce((prices, obj) => {
        prices.highestPrice = Math.max(prices.highestPrice, obj.price);
        prices.lowestPrice = Math.min(prices.lowestPrice, obj.price);
        return prices;
      }, { highestPrice: -Infinity, lowestPrice: Infinity });
      console.log("tarr", tradeArray,highestPrice,lowestPrice);
      setHighestPrice(highestPrice);
      setLowestPrice(lowestPrice);
    }
  }, [tradestate]);

  const getSummary = async () => {
    const urltd =
      "https://api.coingecko.com/api/v3/coins/egold-project-2/market_chart?vs_currency=usd&days=1&precision=5";
    //fetch 24 hour market data
    fetch(urltd)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setTradestate({ data: newData.prices });
      })
      .catch(function (error) {
        console.log(error);
      });

    const marketurl = "https://egold-marketdata.herokuapp.com/v1/summary/";

    //fetch liquidity data
    fetch(marketurl)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setMarketstate({ data: newData, loading: false });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + " K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return noround(num / 1000000, 2) + " M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  function epochToTime(epoch) {
    // Convert epoch to milliseconds
    var date = new Date(epoch);

    // Get components of the date
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    var hours = ('0' + date.getHours()).slice(-2);
    var minutes = ('0' + date.getMinutes()).slice(-2);
    var seconds = ('0' + date.getSeconds()).slice(-2);

    // Construct the human-readable date and time
    var formattedTime =  day + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds;

    return formattedTime;
}



  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length && payload[0].payload) {
      const { name, price } = payload[0].payload;
      return (
        <div className="bg-white text-[12px] px-2 py-1">
          <p className="label">Price : <span className="font-bold">{price}</span></p>
          <p className="label">{`Date and Time :`}</p>
          <p className="label font-bold">{`${epochToTime(name)}`}</p>
        </div>
      );
    }
  
    return null;
  };
  
  return (
    <>
      {tdata.loading ? (
        <div className="w-[232px] h-[399px] shrink-0 bg-[#fff] rounded-[30px] relative shadow-[0_4px_100px_0_rgba(0,0,0,0.1)] z-10 flex justify-center items-center">
          <span className="flex w-[158px] h-[45px] justify-center items-start font-['Poppins'] text-[30px] font-bold leading-[45px] text-[#8d8d8d] tracking--1.5px absolute top-[-50px] left-[40px] text-center whitespace-nowrap z-[14]">
            Egold
          </span>{" "}
          <Loader />
        </div>
      ) : (
        <div className="w-[232px] h-[399px] shrink-0 bg-[#fff] rounded-[30px] relative shadow-[0_4px_100px_0_rgba(0,0,0,0.1)] z-10">
          <span className="flex w-[79px] h-[45px] justify-center items-start font-['Poppins'] text-[30px] font-bold leading-[45px] text-[#8d8d8d] absolute top-[-50px] left-[75px] text-center whitespace-nowrap z-[5]">
            Egold
          </span>
          <div className="w-[90px] h-[44px] font-['Poppins'] text-[20px] font-bold leading-[26px] relative text-center z-[2] mt-[32px] mb-0 mx-auto">
            <span className="font-['Poppins'] text-[22px] charttext font-bold leading-[20px] relative text-center">
            {noround(tdata.data.slice(-1)[0].price, 4)}
              <br />
            </span>
            <span className="font-['Poppins'] text-[14px] font-normal leading-[20px] text-[#000] relative text-center">
              Price
            </span>
          </div>
          <div className="w-[150px] h-[129px] relative z-[36] mt-[19px] mb-0 mx-auto">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={200}
                height={60}
                data={tdata.data}
                margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 5,
                }}
              >
              <Tooltip content={<CustomTooltip />} allowEscapeViewBox={{ x: true, y: true }}/>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <YAxis type="number" domain={['dataMin', 'dataMax']} hide={true}/>
                <Area
                  type="monotone"
                  dataKey="price"
                  stroke="#8884d8"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <div className="w-[172px] h-[90px] relative z-[4] mt-[14px] mr-0 mb-0 ml-[33px]">
            <span className="flex w-[39px] h-[72px] justify-start items-start font-['Poppins'] text-[10px] font-normal leading-[18px] text-[#000] absolute top-0 left-0 text-left overflow-hidden z-[3]">
              Price
              <br />
              High
              <br />
              Low
              <br />
              Volume
            </span>
            <span className="flex w-[55px] h-[90px] justify-end items-start font-['Poppins'] text-[10px] font-bold leading-[18px] text-[#000] absolute top-0 left-[117px] text-right overflow-hidden z-[4]">
              {noround(tdata.data.slice(-1)[0].price, 4)}
              <br />
              {highestPrice=="..." ? "..." : noround(highestPrice,4)}
              <br />
              {lowestPrice=="..." ? "..." : noround(lowestPrice,4)}
              <br />
              {marketstate.loading
                ? "..."
                : noround(marketstate.data.pools.totalUsd * 2,2).toLocaleString()}
              <br />
            </span>
          </div>

          <a href="/#login">
          <div className="flex w-[115px] h-[35px] justify-center items-center chbtnbg bg-cover bg-no-repeat rounded-[17px] relative z-[8] mt-[5px] mb-0 mx-auto">
            <span className="flex w-[82px] h-[18px] justify-center items-start shrink-0 font-['Poppins'] text-[12px] font-bold leading-[18px] text-[#fff] relative text-center whitespace-nowrap z-[8]">
              Trade Egold
            </span>
          </div>
          </a>
        </div>
      )}
    </>
  );
}
