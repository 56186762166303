import React, { useEffect, useState } from "react";
import hourglassimg from "../../images/Sections/TimeLeft/hourglass.png";

export default function TimeLeft() {
  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  const [currentBlockNumber, setCurrentBlockNumber] = useState("...");
  const [timeleft, setTimeLeft] = useState({
    days: null,
    hours: null,
    minutes: null,
    sec: null,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      getSummary();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  async function getSummary() {
    try {
      const currentbk = await web3.eth.getBlockNumber();
      setCurrentBlockNumber(currentbk);
    } catch (error) {
      console.error("Error fetching block number:", error);
    }
  }

  useEffect(() => {
    if (currentBlockNumber !== "...") {
      const blocksRemaining =
        process.env.REACT_APP_HALVING_BLOCK - currentBlockNumber;
      const secondsRemaining = blocksRemaining * 3;
      const days = Math.floor(secondsRemaining / (60 * 60 * 24));
      const hours = Math.floor((secondsRemaining % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((secondsRemaining % (60 * 60)) / 60);
      const seconds = Math.floor(secondsRemaining % 60);

      setTimeLeft({ days: days, hours: hours, minutes: minutes, sec: seconds });
    }
  }, [currentBlockNumber]);

  return (
    <div className=" main-container w-[100%] text-[0px] relative mx-auto my-0 max-w-[900px]">
      <div className="w-[100%] h-[230px] timeleftbg bg-cover bg-no-repeat" />
      <div className="tlbx1 w-[338px] h-[94px] rounded-[21px] absolute top-[59px] left-[5%] z-[3]">
        <div className="flex w-[275px] h-[35px] justify-between items-center relative z-[14] mt-[23px] mr-0 mb-0 ml-[30px]">
          <span className="flex w-[65px] h-[35px] tltext justify-center items-start shrink-0 font-['Poppins'] text-[35px] font-bold leading-[34.65px] tracking--1.75px relative text-center whitespace-nowrap z-[5]">
            {timeleft.days == null ? "..." : timeleft.days}
          </span>
          <span className="flex w-[55px] h-[35px] tltext justify-center items-start shrink-0 font-['Poppins'] text-[35px] font-bold leading-[34.65px] tracking--1.75px relative text-center whitespace-nowrap z-[8]">
            {timeleft.hours == null ? "..." : timeleft.hours}
          </span>
          <span className="flex w-[55px] h-[35px] tltext justify-center items-start shrink-0 font-['Poppins'] text-[35px] font-bold leading-[34.65px] tracking--1.75px relative text-center whitespace-nowrap z-[11]">
            {timeleft.minutes == null ? "..." : timeleft.minutes}
          </span>
          <span className="flex w-[55px] h-[35px] tltext justify-center items-start shrink-0 font-['Poppins'] text-[35px] font-bold leading-[34.65px] tracking--1.75px relative text-center whitespace-nowrap z-[14]">
            {timeleft.sec == null ? "..." : timeleft.sec}
          </span>
        </div>
        <div className="w-[275px] h-[15px] relative z-[15] mt-[-2px] mr-0 mb-0 ml-[37px]">
          <span className="flex w-[34px] h-[15px] justify-center items-start font-['Poppins'] text-[15px] font-[275] leading-[14.85px] tracking--0.75px absolute top-0 left-0 text-center whitespace-nowrap z-[6]">
            days
          </span>
          <span className="flex w-[42px] h-[15px] justify-center items-start font-['Poppins'] text-[15px] font-[275] leading-[14.85px] tracking--0.75px absolute top-0 left-[74px] text-center whitespace-nowrap z-[9]">
            hours
          </span>
          <span className="flex w-[55px] h-[15px] justify-center items-start font-['Poppins'] text-[15px] font-[275] leading-[14.85px] tracking--0.75px absolute top-0 left-[142px] text-center whitespace-nowrap z-[12]">
            minutes
          </span>
          <span className="flex w-[59px] h-[15px] justify-center items-start font-['Poppins'] text-[15px] font-[275] leading-[14.85px] tracking--0.75px absolute top-0 left-[216px] text-center whitespace-nowrap z-[15]">
            seconds
          </span>
        </div>
      </div>
      <div className="tlbx2 flex absolute top-[66px] right-[calc(8%)] gap-[15px] items-center">
        <img className="h-[50px]" src={hourglassimg} />
        <span className="flex w-[190px] h-[80px] tltext justify-start items-start font-['Poppins'] text-[35px] font-bold leading-[35px] text-[#000] tracking--2px text-left overflow-hidden z-[1]">
          Time left
          <br />
          to halving
        </span>
      </div>
    </div>
  );
}
