import React from "react";
import pswap from "../../images/Sections/Partners/pancakeswap.png";
import bsc from "../../images/Sections/Partners/bsc.png";
import solidity from "../../images/Sections/Partners/solidity.png";
import cmc from "../../images/Sections/Partners/cmc.png";
import coingecko from "../../images/Sections/Partners/coingecko.png";
import amazon from "../../images/Sections/Partners/amazon.png";

export default function TechPartners() {
  return (
    <div className="main-container w-[100%] text-[0px] relative mx-auto my-0 max-w-[900px]" id="partners">
      <span className="heads flex justify-center items-start font-['Poppins'] text-[40px] font-bold leading-[60px] text-[#777777] relative text-center whitespace-nowrap mt-0 mb-0 mx-auto">
        Tech Partners
      </span>

      <div className="tpflbx flex flex-wrap justify-center">
        <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div className="h-16">
            <img src={pswap} alt="Pswap Logo" />
          </div>
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div className="h-16">
            <img src={bsc} alt="BSC Logo" />
          </div>
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div className="h-16">
            <img src={solidity} alt="Solidity Logo" />
          </div>
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div className="h-16">
            <img src={cmc} alt="CMC Logo" />
          </div>
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div className="h-16">
            <img src={coingecko} alt="Coingecko Logo" />
          </div>
        </div>
        <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
          <div className="h-16">
            <img src={amazon} alt="Amazon Logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
