import React from "react";

export default function Privacy() {
  return (
    <div className="container mx-auto  mb-16 pb-5 mt-[100px] md:mt-[50px] lg:mt-10 px-10 lg:pb-[30px] bg-[#dfe7ea] rounded-lg">
      <h1 className="uppercase text-center font-bold pt-8 pb-10 text-base text-[#585858] ">
        {window.location.pathname.replace("/", "")}
      </h1>
      <p className="text-[15px] text-[#585858] leading-8">
        EGOLD is committed to protecting your privacy and personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.

        Information Collection: We collect personal information when you interact with our Web3 project, including but not limited to browsing our website, interacting with our dApp, or participating in our community. This information may include your name, email address, wallet address, IP address, and other data necessary to provide our services.

        Information Usage: We use your personal information to provide you with our services, improve our products and services, and communicate with you about our project updates, news, and promotions. We may also use your information for statistical or research purposes.

        Information Sharing: We do not sell or rent your personal information to third parties. However, we may share your information with third-party service providers who help us with our business operations or provide services on our behalf. We may also disclose your personal information to comply with legal obligations or to protect our rights or property.

        Security Measures: We take appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. We use industry-standard security protocols, including encryption, to safeguard your information.

        Data Retention: We retain your personal information for as long as necessary to provide our services or as required by law. We may also retain your information for research or statistical purposes.

        Web3 Privacy: Our Web3 project operates on the blockchain, which is a decentralized and immutable ledger. By using our Web3 project, you acknowledge that your information may be publicly visible on the blockchain. However, we take reasonable measures to protect your privacy on the blockchain, including using pseudonymous wallet addresses and other privacy-enhancing technologies.

        Changes to Privacy Policy: We may update this privacy policy from time to time. We will notify you of any significant changes by posting the new privacy policy on our website.

        Contact Us: If you have any questions about this privacy policy or our data practices, please contact us at contact@egold.farm
        By using our Web3 project, you consent to our privacy policy and data practices.
      </p>
      <br />
    </div>
  );
}
