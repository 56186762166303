import React from "react";
import { useNavigate } from "react-router-dom";
import titlelogo from "../images/headerlogo.png";
import ActiveSupply from "./EGcharts/ActiveSupply";
import TotaleSupply from "./EGcharts/TotalSupply";
import BurntSupply from "./EGcharts/BurntSupply";
import DistributedSupply from "./EGcharts/DistributedSupply";
import DisbursementSupply from "./EGcharts/DisbursementSupply";
import OtherSupply from "./EGcharts/OtherSupply";
import MiningData from "./EGcharts/MiningData";
import MinerData from "./EGcharts/MinerData";
import MinerMH from "./EGcharts/MinerMH";
import Difficulty from "./EGcharts/Difficulty";
import DifficultyCompare from "./EGcharts/DifficultyCompare";
import ProductionCycles from "./EGcharts/ProductionCycles";

export default function TalkCommunity() {
  let history = useNavigate();

  return (
    <div className="mx-auto mb-16 pb-5 pt-[100px] sm:pt-2 lg:pt-10 md:px-10 lg:pb-[30px] rounded-lg">
       <span className="flex  h-[80px] justify-center items-start font-['Poppins'] text-[40px] font-bold leading-[39.6px] text-[#000]  text-left overflow-hidden z-[2]">
         Tokenomics
        </span>
      <div className="bg-[#f6f6f6] p-[12px] lg:px-[25px] rounded">
        <h3 className="font-bold py-2 text-[25px] charthead">EGOLD Supply</h3>
        <div className="grid sm:grid-cols-1 lg:grid-cols-3 lg:gap-10 textalign tokgrid">
          <div className="cocont container mx-auto mt-4 mb-4 p-1 py-3 sm:mt-2 lg:mt-10 lg:p-[30px] bg-[#fff] rounded-lg fullchbox">
            <h5 className="text-center py-[13px] text-[#8c8c8c]">
              Active Supply
            </h5>
            <DistributedSupply />
          </div>
          <div className="cocont container mx-auto mt-4 mb-4 p-1 py-3 sm:mt-2 lg:mt-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg fullchbox">
            <h5 className="text-center py-[13px] text-[#8c8c8c]">
              EGOLD Supply
            </h5>
            <TotaleSupply />
          </div>
          <div className="cocont container mx-auto mt-4 mb-4 p-1 py-3 sm:mt-2 lg:mt-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg fullchbox">
            <h5 className="text-center py-[13px] text-[#8c8c8c]">EGOLD Burn</h5>
            <BurntSupply />
          </div>
          <div className="cocont container mx-auto mt-4 mb-4 p-1 py-3 sm:mt-2 lg:mt-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg fullchbox">
            <h5 className="text-center py-[13px] text-[#8c8c8c]">
              EGOLD Distribution
            </h5>
            <ActiveSupply />
          </div>
          <div className="cocont container mx-auto mt-4 mb-4 p-1 py-3 sm:mt-2 lg:mt-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg fullchbox">
            <h5 className="text-center py-[13px] text-[#8c8c8c]">
              Other Allocations
            </h5>
            <OtherSupply />
          </div>
          <div className="cocont container mx-auto mt-4 mb-4 p-1 py-3 sm:mt-2 lg:mt-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg fullchbox">
            <h5 className="text-center py-[13px] text-[#8c8c8c]">
              Mining Disbursements
            </h5>
            <DisbursementSupply />
          </div>
        </div>
      </div>
      <br />
      <div className="bg-[#f6f6f6] p-[12px] lg:px-[25px] rounded">
        <h3 className="font-bold py-2 text-[25px] charthead">Mining Stats</h3>
        <div className="grid sm:grid-cols-1 lg:grid-cols-3 lg:gap-10 textalign tokgrid">
          <div className="cocont container mx-auto mt-4 mb-4 p-1 py-3 sm:mt-2 lg:mt-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg fullchbox">
            <h5 className="text-center py-[13px] text-[#8c8c8c]">
              Metahash Deployment
            </h5>
            <MiningData />
          </div>
          <div className="cocont container mx-auto mt-4 mb-4 p-1 py-3 sm:mt-2 lg:mt-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg fullchbox">
            <h5 className="text-center py-[13px] text-[#8c8c8c]">
              Miner Sales
            </h5>
            <MinerData />
          </div>
          <div className="cocont container mx-auto mt-4 mb-4 p-1 py-3 sm:mt-2 lg:mt-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg fullchbox">
            <h5 className="text-center py-[13px] text-[#8c8c8c]">
              Miner MH Distribution
            </h5>
            <MinerMH />
          </div>
        </div>
      </div>
      <br />
      <div className="bg-[#f6f6f6] p-[12px] lg:px-[25px] rounded">
        <h3 className="font-bold py-2 text-[25px] charthead">
          Mining Difficulty
        </h3>
        <div className="grid sm:grid-cols-1 lg:grid-cols-3 lg:gap-10 textalign tokgrid">
          <div className="cocont container mx-auto mt-4 mb-4 p-1 py-3 sm:mt-2 lg:mt-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg fullchbox">
            <h5 className="text-center py-[13px] text-[#8c8c8c]">
              System Mining Difficulty
            </h5>
            <p
              className="text-center py-[13px] text-[#8c8c8c]"
              style={{
                fontSize: "10px",
                width: "70%",
                minWidth: "300px",
                margin: "auto",
              }}
            >
              {" "}
              Difficulty is calculated as the number of days it would take for a
              1 MH system to generate 1 EGOLD based on the total Metahash in the
              network
            </p>
            <Difficulty />
          </div>
          <div className="cocont container mx-auto mt-4 mb-4 p-1 py-3 sm:mt-2 lg:mt-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg fullchbox">
            <h5 className="text-center py-[13px] text-[#8c8c8c]">
              Difficulty Comparison
            </h5>

            <DifficultyCompare />
          </div>
          <div className="cocont container mx-auto mt-4 mb-4 p-1 py-3 sm:mt-2 lg:mt-10 lg:p-[30px] bg-[#f8f4e9] rounded-lg fullchbox">
            <h5 className="text-center py-[13px] text-[#8c8c8c]">
              EGOLD Production Cycles
            </h5>
            <ProductionCycles />
          </div>
        </div>
      </div>
      <div className="text-center mt-20">
        <a
          onClick={() => {
            history("/");
          }}
          target="_blank"
          className="sm:mt-0 inline-flex gap-2 items-center justify-center px-4 sm:px-6 py-2 md:py-3 text-xs sm:text-sm font-medium text-center cursor-pointer text-[#253237] bg-[#f6f6f6] rounded-[4px] hover:text-[#253237]"
        >
          Back to Home
        </a>
      </div>
    </div>
  );
}
